import $http from '@/libs/axios'
// import Cookies from "js-cookie";

const localUser = localStorage.getItem('user')

export default {
  state: {
    user: localUser ? JSON.parse(localUser) : null,
  },
  getters: {
    isAuthenticated: state => !!state.user,
    isGuest: state => !state.user,
    isAdmin: state => (state.user && state.user.role.includes('admin')),
    isSupervisor: state => (state.user && state.user.role.includes('supervisor')),
    isAccountManager: state => (state.user && state.user.role.includes('account_manager')),
    isExhibitor: state => (state.user && state.user.role.includes('exhibitor')),
    currentUser: state => state.user ?? {},
  },
  mutations: {
    SET_USER(state, user) {
      if (user) {
        localStorage.setItem('user', JSON.stringify(user))
        $http.defaults.headers.common.Authorization = `Bearer ${user.token}`
      } else {
        localStorage.removeItem('user')
        $http.defaults.headers.common.Authorization = ''
      }
      state.user = user
    },
  },
}
