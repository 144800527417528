import Home from '@/views/Home.vue'
import store from '@/store'

export default [
  {
    path: '/exhibitor',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated && (store.getters.isExhibitor)) {
        store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'horizontal')
        return next()
      }
      if (store.getters.isAdmin) {
        return next({ name: 'dashboard' })
      }
      return next({ name: 'login' })
    },
    children: [
      {
        path: '',
        name: 'exhibitor:my-data',
        component: () => import('@/views/exhibitor/ExhibitorForm.vue'),
        meta: {
          pageTitle: 'main-nav.my_data',
          breadcrumb: [
            {
              text: 'main-nav.my_data',
              active: true,
            },
          ],
        },
      },

      {
        path: '/account-setting',
        name: 'account:setting',
        component: () => import('@/views/account-setting/AccountSetting.vue'),
        meta: {
          pageTitle: 'auth.account-settings',
          breadcrumb: [
            {
              text: 'auth.account-settings',
            },
          ],
        },
      },
      {
        path: '/current-participation',
        name: 'exhibitor:current-participation',
        component: () => import('@/views/participation/CurrentParticipation.vue'),
        meta: {
          pageTitle: 'main-nav.current_participation',
          breadcrumb: [
            {
              text: 'main-nav.current_participation',
            },
          ],
        },
      },
      {
        path: '/previous-participation',
        name: 'exhibitor:previous-participation',
        component: () => import('@/views/participation/PreviousParticipation.vue'),
        meta: {
          pageTitle: 'main-nav.previous_participation',
          breadcrumb: [
            {
              text: 'main-nav.previous_participation',
            },
          ],
        },
      },
      {
        path: '/offers',
        name: 'exhibitor:offers',
        component: () => import('@/views/offer/Offers.vue'),
        meta: {
          pageTitle: 'main-nav.offers',
          breadcrumb: [
            {
              text: 'main-nav.offers',
            },
          ],
        },
      },

    ],
  },
]
