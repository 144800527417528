export default {
  state: {
    accountManagers: [],
    accountManagerOptions: [],
  },
  getters: {
    accountManagers: state => state.accountManagers,
    accountManagerOptions: state => state.accountManagerOptions,
  },
  mutations: {
    SET_ACCOUNT_MANAGER_OPTIONS(state, accountManagers) {
      state.accountManagers = accountManagers
      state.accountManagerOptions = accountManagers.map(e => ({ ...e, value: e.id, label: `${e.first_name} ${e.last_name}` }))
    },
  },
}
