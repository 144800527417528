import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import user from './user'
import map from './map'
import exhibition from './exhibition'
import sector from './sector'
import exhibitionTypes from './exhibition-type'
import accountManagers from './account-manager'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    user,
    map,
    exhibition,
    sector,
    exhibitionTypes,
    accountManagers,
  },
  strict: process.env.DEV,
})
