import i18n from '@/libs/i18n'

export default {
  state: {
    sectors: [],
    sectorOptions: [],
    sectorChildrenOptions: [],
  },
  getters: {
    sectors: state => state.sectors,
    sectorOptions: state => state.sectorOptions.map(option => ({ ...option, label: option.label[i18n.locale] })),
    sectorChildrenOptions: state => state.sectorChildrenOptions.map(option => ({ ...option, label: option.label[i18n.locale] })),
  },
  mutations: {
    SET_SECTOR_OPTIONS(state, sectors) {
      state.sectors = sectors
      state.sectorOptions = sectors.map(e => ({ ...e, value: e.id, label: e.title }))
    },
    SET_SECTOR_CHILDREN_OPTIONS(state, sectors) {
      state.sectors = sectors
      state.sectorChildrenOptions = sectors.map(e => ({ ...e, value: e.id, label: e.title }))
    },
  },
}
