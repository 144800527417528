import Home from '@/views/Home.vue'
import store from '@/store'

export default [
  {
    path: '/',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated && (store.getters.isAdmin || store.getters.isSupervisor || store.getters.isAccountManager)) {
        store.commit('appConfig/UPDATE_LAYOUT_TYPE', 'vertical')
        return next()
      }
      if (store.getters.isExhibitor) {
        return to.path === '/' ? next({ name: 'exhibitor:my-data' }) : next()
      }
      return next({ name: 'login' })
    },
    children: [
      {
        path: '',
        name: 'dashboard',
        component: () => import('@/views/Dashboard.vue'),
        meta: {
          pageTitle: 'main-nav.home',
          breadcrumb: [
            {
              text: 'main-nav.home',
              active: true,
            },
          ],
        },
      },
      {
        path: 'exhibitions',
        component: { render: h => h('router-view') },
        children: [
          {
            path: '',
            name: 'exhibitions',
            component: () => import('@/views/exhibition-type/ExhibitionTypes.vue'),
            meta: {
              pageTitle: 'exhibitions.exhibitions',
              breadcrumb: [
                {
                  text: 'exhibitions.exhibitions',
                  active: true,
                },
              ],
            },
          },
          {
            path: 'edit/:id?',
            component: { render: h => h('router-view') },
            children: [
              {
                name: 'exhibitions:edit',
                path: '',
                component: () => import('@/views/exhibition-type/ExhibitionTypeDetails.vue'),
                meta: {
                  pageTitle: 'exhibitions.exhibitions',
                  breadcrumb: [
                    {
                      text: 'exhibitions.exhibitions',
                      to: { name: 'exhibitions' },
                    },
                    {
                      text: 'exhibitions.edit-exhibition',
                      active: true,
                    },
                  ],
                },
              },
              {
                path: 'sessions',
                component: { render: h => h('router-view') },
                children: [
                  {
                    path: 'new',
                    name: 'sessions:new',
                    component: () => import('@/views/exhibition/ExhibitionForm.vue'),
                    meta: {
                      pageTitle: 'exhibitions.exhibitions',
                      breadcrumb: [
                        {
                          text: 'exhibitions.exhibitions',
                          to: { name: 'exhibitions' },
                        },
                        {
                          text: () => store.getters.currentSessionExhibitionName,
                          to: () => store.getters.currentSessionExhibitionRoute,
                        },
                        {
                          text: 'exhibitions.add-exhibition',
                          active: true,
                        },
                      ],
                    },
                  },
                  {
                    path: ':sessionId?',
                    name: 'sessions:edit',
                    component: () => import('@/views/exhibition/ExhibitionForm.vue'),
                    meta: {
                      pageTitle: 'exhibitions.exhibitions',
                      breadcrumb: [
                        {
                          text: 'exhibitions.exhibitions',
                          to: { name: 'exhibitions' },
                        },
                        {
                          text: () => store.getters.currentSessionExhibitionName,
                          to: () => store.getters.currentSessionExhibitionRoute,
                        },
                        {
                          text: 'exhibitions.edit-exhibition',
                          active: true,
                        },
                      ],
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: 'exhibitors',
        component: { render: h => h('router-view') },
        children: [
          {
            path: '',
            name: 'exhibitors',
            component: () => import('@/views/exhibitor/Exhibitors.vue'),
            meta: {
              pageTitle: 'main-nav.exhibitors',
              breadcrumb: [
                {
                  text: 'main-nav.exhibitors',
                  active: true,
                },
              ],
            },
          },
          {
            path: 'new',
            name: 'exhibitors:new',
            component: () => import('@/views/exhibitor/ExhibitorForm.vue'),
            meta: {
              pageTitle: 'exhibitors.exhibitors',
              breadcrumb: [
                {
                  text: 'exhibitors.exhibitors',
                  to: { name: 'exhibitors' },
                },
                {
                  text: 'exhibitors.add-exhibitor',
                  active: true,
                },
              ],
            },
          },
          {
            path: 'edit/:id?',
            name: 'exhibitors:edit',
            component: () => import('@/views/exhibitor/ExhibitorForm.vue'),
            meta: {
              pageTitle: 'exhibitors.exhibitors',
              breadcrumb: [
                {
                  text: 'exhibitors.exhibitors',
                  to: { name: 'exhibitors' },
                },
                {
                  text: 'exhibitors.edit-exhibitor',
                  active: true,
                },
              ],
            },
          },
        ],
      },

      {
        path: '/account-setting',
        component: () => import('@/views/account-setting/AccountSetting.vue'),
        meta: {
          pageTitle: 'auth.account-settings',
          breadcrumb: [
            {
              text: 'auth.account-settings',
            },
          ],
        },
      },
      {
        path: '/visitors',
        name: 'visitors',
        component: () => import('@/views/visitors/Visitors.vue'),
        meta: {
          pageTitle: 'main-nav.visitors',
          breadcrumb: [
            {
              text: 'main-nav.visitors',
            },
          ],
        },
      },
      {
        path: '/offers',
        name: 'offers',
        component: () => import('@/views/offer/Offers.vue'),
        meta: {
          pageTitle: 'main-nav.offers',
          breadcrumb: [
            {
              text: 'main-nav.offers',
            },
          ],
        },
      },
      {
        path: '/advertisements',
        name: 'advertisements',
        component: () => import('@/views/advertisements/Advertisements.vue'),
        meta: {
          pageTitle: 'main-nav.advertisements',
          breadcrumb: [
            {
              text: 'main-nav.advertisements',
            },
          ],
        },
      },
      {
        path: '/notification',
        name: 'notifications',
        component: () => import('@/views/notifications/Notifications.vue'),
        meta: {
          pageTitle: 'main-nav.notifications',
          breadcrumb: [
            {
              text: 'main-nav.notifications',
            },
          ],
        },
      },
      {
        path: '/notification/list',
        name: 'notifications:list',
        component: () => import('@/views/notifications/NotificationsList.vue'),
        meta: {
          pageTitle: 'main-nav.notifications',
          breadcrumb: [
            {
              text: 'main-nav.notifications',
            },
          ],
        },
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('@/views/user/Users.vue'),
        meta: {
          pageTitle: 'main-nav.users',
          breadcrumb: [
            {
              text: 'main-nav.users',
            },
          ],
        },
      },
      {
        path: '/activity-logs',
        name: 'activity-logs',
        component: () => import('@/views/activity-log/ActivityLogs.vue'),
        meta: {
          pageTitle: 'main-nav.activity-logs',
          breadcrumb: [
            {
              text: 'main-nav.activity-logs',
            },
          ],
        },
      },
      {
        path: 'surveys',
        component: { render: h => h('router-view') },
        children: [
          {
            path: '',
            name: 'surveys',
            component: () => import('@/views/survey/Surveys.vue'),
            meta: {
              pageTitle: 'surveys.surveys',
              breadcrumb: [
                {
                  text: 'surveys.surveys',
                  active: true,
                },
              ],
            },
          },
          {
            path: 'edit/:id?',
            component: { render: h => h('router-view') },
            children: [
              {
                name: 'surveys:edit',
                path: '',
                component: () => import('@/views/survey/SurveyDetails.vue'),
                meta: {
                  pageTitle: 'surveys.surveys',
                  breadcrumb: [
                    {
                      text: 'surveys.surveys',
                      to: { name: 'surveys' },
                    },
                    {
                      text: 'surveys.edit-survey',
                      active: true,
                    },
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
]
