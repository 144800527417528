export default {
  namespaced: true,
  state: {
    modal: false,
    exhibitionId: null,
    hallId: -1,
  },
  getters: {
    isOpen: state => state.modal,
    selectedHallId: state => state.hallId,
    selectedExhibitionId: state => state.exhibitionId,
  },
  mutations: {
    OPEN_MODAL(state, { exhibitionId, hallId } = {}) {
      state.exhibitionId = exhibitionId
      state.hallId = hallId
      state.modal = true
    },
    CLOSE_MODAL(state) {
      state.exhibitionId = null
      state.hallId = -1
      state.modal = false
    },
  },
}
