import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRoutes from './auth-routes'
import DashboardRoutes from './dashboard-routes'
import ExhibitorRoutes from './exhibitor-routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...DashboardRoutes,
    ...ExhibitorRoutes,
    ...AuthRoutes,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

export default router
