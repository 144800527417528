import queryString from 'query-string'
import cloneDeep from 'lodash/cloneDeep'
import isNil from 'lodash/isNil'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import isBoolean from 'lodash/isBoolean'
import toString from 'lodash/toString'
import isPlainObject from 'lodash/isPlainObject'
import isArray from 'lodash/isArray'

class FormsService {
  static MULTIPART_HEADER = { 'Content-Type': 'multipart/form-data' };

  static convertObjectToFormData(form) {
    const formData = new FormData()
    // eslint-disable-next-line prefer-arrow-callback
    Object.keys(form).forEach(key => {
      let value = form[key]
      if (isNil(value)) {
        return false
      }
      if (isString(value) || isNumber(value)) {
        return formData.append(key, toString(value))
      }
      if (isBoolean(value)) {
        return formData.append(key, value ? '1' : '0')
      }
      if (value instanceof Blob) {
        const extension = form[key].type.includes('video') ? 'mp4' : 'jpg'
        return formData.append(key, form[key], `${key}.${extension}`)
      }
      if (isArray(value)) {
        const arrayOfObjects = value.length && isPlainObject(value[0])

        if (arrayOfObjects) {
          return formData.append(key, JSON.stringify(form[key]))
        }

        if (form[key].length === 0) return false
        if (form[key][0] instanceof Blob) {
          form[key].forEach((image, idx) => {
            const extension = image?.type?.includes('video') ? 'mp4' : 'jpg'
            formData.append(`${key}[${idx}]`, image, `${key}-${idx}.${extension}`)
          })
        } else {
          formData.append(key, form[key].map(i => JSON.stringify(i)).join(','))
        }
        return false
      }
      if (isPlainObject(value)) {
        value = cloneDeep(value)
        // eslint-disable-next-line no-shadow
        Object.keys(value).forEach(key => {
          if (isBoolean(value[key])) {
            value[key] = value[key] ? '1' : '0'
          }
        })
        return formData.append(key, JSON.stringify(value))
      }
      return true
    })
    return formData
  }

  static convertStringToBoolean(object) {
    Object.keys(object).forEach(key => {
      if (typeof object[key] === 'string' && (object[key] === '1' || object[key] === '0')) {
        // eslint-disable-next-line no-param-reassign
        object[key] = parseInt(object[key], 10)
      } else if (typeof object[key] === 'object' && object[key] !== null) {
        // eslint-disable-next-line no-param-reassign
        object[key] = this.convertStringToBoolean(object[key])
      }
    })
    return object
  }

  static convertBooleanToBinary(object) {
    Object.keys(object).forEach(key => {
      if (typeof object[key] === 'boolean') {
        // eslint-disable-next-line no-param-reassign
        object[key] = object[key] ? 1 : 0
      } else if (typeof object[key] === 'object' && object[key] !== null) {
        // eslint-disable-next-line no-param-reassign
        object[key] = this.convertBooleanToBinary(object[key])
      }
    })
    return object
  }

  static prepareQueryString(_query, convertToString = false) {
    const query = cloneDeep(_query)
    Object.keys(query).forEach(key => {
      // eslint-disable-next-line no-prototype-builtins
      if (query.hasOwnProperty(key)) {
        if (isNil(query[key]) || query[key]?.length === 0) {
          delete query[key]
        } else if (isArray(query[key])) {
          query[key] = query[key].join(',')
        } else if (!isString(query[key])) {
          query[key] = toString(query[key])
        }
      }
    })
    return convertToString ? queryString.stringify(query) : query
  }
}

export default FormsService
