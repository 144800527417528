import Vue from 'vue'

// axios
import axios from 'axios'
import { API_URL } from '@/constants/config'
import i18n from '@/libs/i18n'

const localUser = localStorage.getItem('user')
const token = localUser ? JSON.parse(localUser).token : null

const dummyHeader = process.env.VUE_APP_DUMMY === '1' ? { os: 'ios' } : {}

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: API_URL,
  // timeout: 1000,
  headers: {
    common: {
      'Access-Control-Allow-Origin': '*',
      Authorization: token ? `Bearer ${token}` : '',
      ...dummyHeader,
    },

  },
})

axiosIns.interceptors.request.use(config => {
  // eslint-disable-next-line no-param-reassign
  config.headers.locale = i18n.locale
  // config.headers.Authorization = token

  return config
})

Vue.prototype.$http = axiosIns

export default axiosIns
