import i18n from '@/libs/i18n'

export default {
  state: {
    exhibitionTypes: [],
    sessionOptions: [],
    exhibitionTypeOptions: [],
  },
  getters: {
    exhibitionTypes: state => state.exhibitionTypes,
    sessionOptions: state => state.sessionOptions,
    exhibitionTypeOptions: state => state.exhibitionTypeOptions,
  },
  mutations: {
    SET_EXHIBITIONS_OPTIONS(state, exhibitionTypes) {
      state.exhibitionTypes = exhibitionTypes
      state.exhibitionTypeOptions = exhibitionTypes.map(e => ({ ...e, value: e.id, title: `${e.title[i18n.locale]}` }))
      state.sessionOptions = exhibitionTypes.reduce((prev, curr) => ([...prev, ...curr.exhibitions.map(e => ({ ...e, value: e.id, title: `${curr.title[i18n.locale]} ${e.title}` }))]), [])
    },
  },
}
