import dayjs from 'dayjs'

class StorageService {
  static setItem(key, value, expires = 365, userId = 0) {
    try {
      const expiresDate = typeof expires === 'number' ? dayjs().add(expires, 'days') : dayjs(expires)
      const storedValue = {
        userId,
        expires: expiresDate.toISOString(),
        value,
      }
      localStorage.setItem(key, JSON.stringify(storedValue))
    } catch (e) {
      console.error('LocalStorage not set probably -> Storage.setItem', e)
    }
  }

  static getItem(key, defaultValue = undefined, userId = 0) {
    try {
      const storedValue = localStorage.getItem(key)
      let value

      if (!storedValue) return undefined

      try {
        value = JSON.parse(storedValue)
      } catch (e) {
        value = storedValue
      }
      const storedUserId = value?.userId

      if (!value) return storedValue
      if (!value?.expires) return storedValue
      if (storedUserId && userId && storedUserId !== userId) return undefined
      if (dayjs(value?.expires).isBefore(dayjs())) {
        StorageService.removeItem(key)
        return defaultValue
      }
      return value?.value ?? value
    } catch (e) {
      console.error('LocalStorage not set probably -> Storage.getItem', e)
      return defaultValue
    }
  }

  static removeItem(key) {
    try {
      localStorage.removeItem(key)
    } catch (e) {
      console.error('LocalStorage not set probably -> Storage.removeItem', e)
    }
  }
}

export default StorageService
