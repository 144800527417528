import StorageService from '@/services/storage-service'
import i18n from '@/libs/i18n'
import store from '@/store'
import { localize } from 'vee-validate'

export default class LocalizationService {
  static initLocale() {
    const storedLocale = StorageService.getItem('expo_locale')
    const locale = (storedLocale || 'ar').substring(0, 2)
    this.updateLocale(locale)
  }

  static updateLocale(locale) {
    i18n.locale = locale
    LocalizationService.updateDirection(locale)
    localize(locale)
  }

  static translate(key) {
    return i18n.t(key)
  }

  static setLocale(locale) {
    LocalizationService.updateLocale(locale)
    StorageService.setItem('expo_locale', locale)
  }

  static setLocaleFromBranch(branch) {
    if (branch?.locales?.length && !branch.locales.includes(i18n.locale)) {
      LocalizationService.setLocale(branch.locales[0])
    }
  }

  static getLocale() {
    return StorageService.getItem('expo_locale')
  }

  static getCurrentLocale() {
    return i18n.locale
  }

  static updateDirection(locale) {
    const shouldRTL = ['ar', 'ur'].includes(locale)
    store.commit('appConfig/SET_RTL', shouldRTL)

    if (document?.documentElement?.setAttribute) {
      document.documentElement.setAttribute('dir', shouldRTL ? 'rtl' : 'ltr')
      document.documentElement.setAttribute('lang', locale)
    }
  }

  static getTranslate(object, locale, withoutFallback = false) {
    const currentLocale = locale ?? LocalizationService.getLocale()
    return object?.[currentLocale] ?? (withoutFallback ? '' : object?.ar) ?? ''
  }

  static getTranslatedLocale(object, locale, withoutFallback = false) {
    return LocalizationService.getTranslate(object, locale, withoutFallback)
  }

  static getLocaleIndex(object, locale) {
    const localesArray = object?.locale ?? object?.locales ?? object
    if (!Array.isArray(localesArray)) {
      return -1
    }
    return localesArray?.findIndex(l => l.locale === locale) ?? -1
  }
}
