import $http from '@/libs/axios'
import queryString from 'query-string'

class MapAPI {
  static async maps(filters = {}) {
    const query = queryString.stringify(filters)
    return (await $http.get(`/maps?${query}`)).data.data
  }

  static async create(form) {
    return (await $http.post('/maps', form, {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    })).data
  }

  static async delete(id) {
    return (await $http.delete(`/maps/${id}`)).data
  }
}

export default MapAPI
