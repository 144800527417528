<template>
  <div>
    <l-map
      ref="map"
      :crs="crs"
      :center="center"
      :zoom="zoom"
      :min-zoom="minZoom"
      :max-zoom="maxZoom"
      :bounds="bounds"
      :max-bounds="maxBounds"
      class="mx-auto"
      style="height: 400px; width: 100%"
      @click="handleClick"
    >
      <l-image-overlay
        :url="imageUrl"
        :bounds="bounds"
        :attribution="attribution"
      />
      <l-marker
        v-for="(marker, idx) in markers"
        :key="idx"
        :lat-lng="marker"
        :draggable="true"
        @update:latLng="updateMarker($event, idx)"
        @click="handleMarkerClick($event, idx)"
      >
        <l-icon
          :icon-size="[32, 37]"
          :icon-anchor="[32, 37]"
          :icon-url="selectedMarkerIdx === idx ? '/pins/pin-filled.png' : '/pins/pin-outline.png'"
        />
        <l-tooltip>Exhibitor</l-tooltip>
      </l-marker>
    </l-map>

  </div>
</template>

<script>
import {
  LMap, LImageOverlay, LMarker, LIcon, LTooltip,
} from 'vue2-leaflet'
import {
  CRS, latLng, Icon,
} from 'leaflet'

export default {
  name: 'HallMap',
  components: {
    LMap, LMarker, LImageOverlay, LIcon, LTooltip,
  },
  props: {
    markers: {
      type: Array,
      default: () => [],
    },
    selectedMarkerIdx: {
      type: Number,
      default: 0,
    },
    imageUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      map: null,
      bounds: [[0, 0], [400, 400]],
      maxBounds: [[0, 0], [400, 400]],
      center: latLng(100, 100),
      zoom: 0,
      maxZoom: 2,
      minZoom: -5,
      crs: CRS.Simple,
      attribution: 'Hall 11',
    }
  },
  watch: {
    imageUrl() {
      this.setBounds()
    },
  },
  mounted() {
    // eslint-disable-next-line no-underscore-dangle
    delete Icon.Default.prototype._getIconUrl
    Icon.Default.mergeOptions({
      // eslint-disable-next-line global-require
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      // eslint-disable-next-line global-require
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      // eslint-disable-next-line global-require
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })
    this.setBounds()
  },
  methods: {
    handleReady() {
      this.map = this.$refs.myMap.mapObject
    },
    handleClick(e) {
      this.$emit('add', e.latlng)
    },
    updateMarker(event, markerIdx) {
      this.$emit('update', { index: markerIdx, latLng: event })
    },
    handleMarkerClick(e, idx) {
      this.$emit('click', idx)
    },
    setBounds() {
      if (!this.imageUrl) {
        return
      }
      const img = new Image()
      img.onload = () => {
        this.bounds = [[0, 0], [img.height, img.width]]
        this.maxBounds = [[0, 0], [img.height, img.width]]
      }
      img.src = this.imageUrl
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../../node_modules/leaflet/dist/leaflet.css';
</style>
