<template>
  <v-select
    :value="value"
    label="title"
    :reduce="(e) => e.id"
    :options="options"
    :filterable="false"
    @search="fetchOptions"
    @input="selectedId = $event; $emit('input', $event)"
  />
</template>

<script>
import vSelect from 'vue-select'
import ExhibitorAPI from '@/API/ExhibitorAPI'
import uniqBy from 'lodash/uniqBy'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'ExhibitorAutoComplete',
  components: { vSelect },
  props: {
    value: {
      type: Number,
      default: null,
    },
    except: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: [],
      selectedId: null,
    }
  },
  watch: {
    value(value) {
      if (value && value !== this.selectedId && this.options.find(option => option.id !== value)) {
        this.getExhibitors({ title: this.value, except: this.except.join(',') })
      }
    },
  },
  mounted() {
    this.selectedId = this.value
    if (this.value) {
      this.getExhibitors({ title: this.value, except: this.except.join(',') })
    } else {
      this.getExhibitors({ except: this.except.join(',') })
    }
  },
  methods: {
    fetchOptions(search, loading) {
      loading(true)
      this.getExhibitors({ title: search, except: this.except.join(',') }).finally(() => loading(false))
    },
    getExhibitors(filters) {
      return ExhibitorAPI.exhibitors(filters)
        .then(({ data }) => {
          this.options = uniqBy(data.map(e => ({ ...e, title: LocalizationService.getTranslate(e.name) })).concat(this.options), option => option.id)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
