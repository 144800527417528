import $http from '@/libs/axios'
import queryString from 'query-string'
import FormsService from '@/services/forms-service'

class ExhibitorAPI {
  static async exhibitors(filters = {}) {
    const query = queryString.stringify(filters)
    return (await $http.get(`dashboard/exhibitors?${query}`)).data.data
  }

  static async exportExhibitors(exhibitionId) {
    return (await $http.get(`dashboard/export-exhibitors?exhibition_id=${exhibitionId}`, { responseType: 'blob' })).data
  }

  static async exportStandNames(exhibitionId) {
    return (await $http.get(`dashboard/export-stand-names?exhibition_id=${exhibitionId}`, { responseType: 'blob' })).data
  }

  static async exportBadges(exhibitionId) {
    return (await $http.get(`dashboard/export-badges?exhibition_id=${exhibitionId}`, { responseType: 'blob' })).data
  }

  static async show(id) {
    return (await $http.get(`dashboard/exhibitors/${id}`)).data.data
  }

  static async create(form) {
    return (await $http.post('dashboard/exhibitors/create', form)).data.data
  }

  static async process(form) {
    return (await $http.post('dashboard/exhibitors', form, { header: FormsService.MULTIPART_HEADER })).data.data
  }

  static async products(form) {
    return (await $http.post('dashboard/exhibitors', form, {
      header: {
        'Content-Type': 'multipart/form-data',
      },
    })).data.data
  }

  static async contacts(form) {
    return (await $http.post('dashboard/exhibitors', form)).data.data
  }

  static async delete(id) {
    return (await $http.delete(`dashboard/exhibitors/${id}`)).data
  }

  static async updateStatus(id, status) {
    return (await $http.get(`dashboard/exhibitors/${id}/${status}`)).data
  }
}

export default ExhibitorAPI
