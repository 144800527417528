import $http from '@/libs/axios'
import queryString from 'query-string'

class ExhibitionAPI {
  static async exhibitions(filters = {}) {
    const query = queryString.stringify(filters)
    return (await $http.get(`dashboard/exhibitions?${query}`)).data.data
  }

  static async exhibitionsOptions() {
    return (await $http.get('dashboard/exhibitions/options')).data.data
  }

  static async show(id) {
    return (await $http.get(`dashboard/exhibitions/${id}`)).data.data
  }

  static async process(form) {
    return (await $http.post('dashboard/exhibitions', form)).data.data
  }

  static async update(id, form) {
    return (await $http.post(`/exhibitions/${id}`, form, {
      header: {
        'Content-Type': 'multipart/form-data',
      },
      method: 'patch',
    })).data
  }

  static async delete(id) {
    return (await $http.delete(`dashboard/exhibitions/${id}`)).data
  }

  static async updateStatus(filters = {}) {
    const query = queryString.stringify(filters)
    return (await $http.get(`/exhibitions/status?${query}`)).data.data
  }

  static async generateBadges(exhibitionId) {
    return $http.get(`dashboard/badges/${exhibitionId}`, { responseType: 'arraybuffer' })
  }
}

export default ExhibitionAPI
