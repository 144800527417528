import i18n from '@/libs/i18n'

export default {
  state: {
    currentSession: null,
  },
  getters: {
    currentSessionExhibitionName: state => state.currentSession?.exhibition_type?.title?.[i18n.locale] ?? i18n.t('exhibitions.exhibition').toString(),
    currentSessionExhibitionRoute: state => {
      const id = state.currentSession?.exhibition_type_id
      return id ? { name: 'exhibitions:edit', params: { id }, hash: '#1' } : { name: 'exhibitions' }
    },
  },
  mutations: {
    SET_CURRENT_SESSION(state, session) {
      state.currentSession = session
    },
  },
}
