<template>
  <b-modal
    ref="modal"
    size="xl"
    title="Halls Manager"
    ok-title="Submit"
    cancel-title="Cancel"
    centered
    no-close-on-backdrop
    @ok="handleSubmitForm"
    @hidden="handleCloseModal"
  >
    <b-row>
      <b-col
        sm="12"
        md="6"
      >
        <b-form>
          <b-form-group>
            <label for="exhibition-select">Exhibition</label>
            <v-select
              id="exhibition-select"
              v-model="form.exhibition_id"
              label="title"
              :options="exhibitions"
              @input="getMaps"
            />
          </b-form-group>
          <b-form-group v-if="form.exhibition_id">
            <label for="hall-select">Hall</label>
            <v-select
              id="hall-select"
              v-model="form.hall_id"
              label="hall"
              :reduce="h => h.id"
              :options="halls"
              @change="handleSelectHall"
            />
          </b-form-group>
          <div v-if="form.hall_id === -1 && form.exhibition_id">
            <b-form-group label="Map Image">
              <b-form-file
                v-model="form.map"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                accept="image/jpeg, image/png"
                @input="handleSelectMapImage"
              />
            </b-form-group>
            <b-form-group
              v-if="form.map"
              label="Add name to this Hall"
            >
              <b-form-input
                v-model="form.hall_name"
                type="text"
                placeholder="Hall name (ex: Hall-5)"
              />
            </b-form-group>
          </div>
          <b-card v-if="form.exhibitors.length">
            <b-row
              v-for="(exhibitor, index) in form.exhibitors"
              :id="exhibitor.id"
              :key="exhibitor.id"
              ref="row"
            >
              <b-col
                md="6"
                class="d-flex align-items-center"
              >
                <b-form-group
                  class="flex-grow-1 mb-0"
                  label-for="exhibitor-name"
                >
                  <exhibitor-auto-complete
                    id="exhibitor-name"
                    v-model="exhibitor.id"
                    :except="exhibitorsIds.filter(id => id !== exhibitor.id)"
                    class="flex-grow-1"
                    type="text"
                    placeholder="exhibitor name"
                  />
                </b-form-group>
                <feather-icon
                  v-if="selectedMarkerIdx === index"
                  icon="MapPinIcon"
                  class="text-danger mx-1"
                />
              </b-col>
              <b-col md="2">
                <b-form-group
                  class="mb-0"
                  label-for="stand"
                >
                  <b-form-input
                    id="stand"
                    v-model="exhibitor.stand"
                    type="text"
                    placeholder=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  class="mb-0"
                  label-for="latitude"
                >
                  <b-form-input
                    id="latitude"
                    v-model="exhibitor.lat_lng.lat"
                    :readonly="true"
                    type="number"
                    placeholder="32"
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group
                  class="mb-0"
                  label-for="longitude"
                >
                  <b-form-input
                    id="longitude"
                    v-model="exhibitor.lat_lng.lng"
                    :readonly="true"
                    type="number"
                    placeholder="15"
                  />
                </b-form-group>
              </b-col>

              <b-col
                lg="2"
                md="3"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  @click="form.exhibitors.splice(index, 1)"
                >
                  <feather-icon
                    icon="TrashIcon"
                  />
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>

          </b-card>
        </b-form>
      </b-col>
      <b-col
        sm="12"
        md="6"
      >
        <hall-map
          v-if="showModal && mapPreview"
          :markers="markers"
          :selected-marker-idx="selectedMarkerIdx"
          :image-url="mapPreview"
          @add="handleAddMarker"
          @click="handleClickMarker"
          @update="handleUpdateMarker"
        />
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import HallMap from '@/components/map/HallMap.vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
  BCol, BRow, BModal, BForm, BFormGroup, BCard, BFormInput, BButton, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ExhibitorAutoComplete from '@/components/autocomplete/ExhibitorAutoComplete.vue'
import ExhibitionAPI from '@/API/ExhibitionAPI'
import MapAPI from '@/API/MapAPI'
import { mapGetters } from 'vuex'

export default {
  name: 'ExhibitorsMapModal',
  directives: { Ripple },
  components: {
    ExhibitorAutoComplete,
    HallMap,
    BCol,
    BRow,
    BModal,
    vSelect,
    BForm,
    BFormGroup,
    BCard,
    BFormInput,
    BButton,
    BFormFile,
  },
  data() {
    return {
      showModal: false,
      form: {
        exhibition_id: null,
        hall_id: -1,
        map: null,
        hall_name: '',
        exhibitors: [],
      },
      selectedMarkerIdx: null,
      exhibitions: [],
      maps: [],
      mapPreview: '',
    }
  },
  computed: {
    ...mapGetters({
      opened: 'map/isOpen',
      selectedHallId: 'map/selectedHallId',
      selectedExhibitionId: 'map/selectedExhibitionId',
    }),
    markers() {
      return this.form.exhibitors.map(e => e.lat_lng)
    },
    exhibitorsIds() {
      return this.form.exhibitors.map(e => e.id)
    },
    halls() {
      return [
        { id: -1, hall: 'Add new map' },
        ...this.maps,
      ]
    },
    filters() {
      return {
        exhibition_id: this.form.exhibition_id ? this.form.exhibition_id.id : null,
      }
    },
  },
  watch: {
    opened(show) {
      if (show) {
        this.showModal = false
        setTimeout(() => this.showModal = true, 200)
        return this.$refs.modal.show()
      }
      return this.$refs.modal.hide()
    },
    filters() {
      this.getMaps()
    },
    selectedHallId(val) {
      this.form.hall_id = val
      if (val && val !== -1) {
        this.handleSelectHall(val)
      }
    },
    selectedExhibitionId(exhibitionId) {
      if (!exhibitionId) {
        return
      }
      const exhibitionObject = this.exhibitions.find(e => e.id === exhibitionId)
      this.form.exhibition_id = exhibitionObject || { id: exhibitionId }
    },
  },
  mounted() {
    this.getExhibitions()
    this.getMaps()
  },
  methods: {
    handleAddMarker(latLng) {
      if (this.form.exhibitors.length && !this.form.exhibitors[0].id) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please Set Exhibitor',
            icon: 'AlertOctagonIcon',
            text: 'The exhibitor for the first one',
            variant: 'warning',
          },
        })
        return
      }
      this.form.exhibitors = [{ id: null, lat_lng: latLng }, ...this.form.exhibitors]
      this.selectedMarkerIdx = 0
    },
    handleClickMarker(markerIndex) {
      this.selectedMarkerIdx = markerIndex
    },
    handleUpdateMarker({ index, latLng }) {
      this.form.exhibitors[index].lat_lng = latLng
      this.$set(this.form.exhibitors, index, { id: this.form.exhibitors[index].id, lat_lng: latLng })
    },
    handleSelectMapImage() {
      if (!this.form.map || typeof this.form.map === 'string') {
        this.mapPreview = this.form.map
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => this.mapPreview = reader.result
      reader.readAsDataURL(this.form.map)
    },
    getExhibitions() {
      ExhibitionAPI.exhibitions().then(exhibitions => this.exhibitions = exhibitions.map(e => ({ ...e, title: e.title.en })))
    },
    getMaps() {
      MapAPI.maps(this.filters).then(maps => {
        this.maps = maps
        /* if (this.form.hall_id !== -1) {
          const map = this.maps.find(e => e.id === this.form.hall_id)
          this.form.map = map.map_url
          this.handleSelectMapImage()
          this.form.hall_name = map.hall
        } */
      }).catch(error => console.log(error))
    },
    handleSubmitForm(e) {
      e.preventDefault()
      const formData = new FormData()
      formData.append('image', this.form.map, 'map.png')
      formData.append('exhibition_id', this.form.exhibition_id.id)
      formData.append('hall', this.form.hall_name)
      formData.append('stands', JSON.stringify(this.form.exhibitors))

      MapAPI.create(formData).then()
    },
    handleCloseModal() {
      this.$store.commit('map/CLOSE_MODAL')
    },
    handleSelectHall(hallId) {
      const map = this.maps.find(e => e.id === hallId)
      if (!map) {
        console.error(`Map not found ${hallId}`, [...this.maps])
        return
      }
      this.form.map = map.map_url
      this.handleSelectMapImage()
      this.form.hall_name = map.hall
      this.form.exhibitors = map.stands.map(stand => ({
        lat_lng: { lat: stand.latitude, lng: stand.longitude }, id: stand.exhibitor_id, stand: stand.name, title: stand.exhibitor_name,
      }))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
